<template>
  <div class="my-box">
    <div class="top-content">
      <Header :title="'保单详情'" :back="'my/guarantee'"/>
      <div class="property">
        <div class="property-title">{{guaranteeList.length?guaranteeList[0].name:'-'}}</div>
        <!-- <div class="property-subtitle">已保障{{guaranteeList.length?guaranteeList[0].days:'-'}}天</div> -->
        <div class="property-subtitle2">保单编号：{{guaranteeList.length?guaranteeList[0].code:'-'}} <a><span class="theme-color" @click="doCopy(guaranteeList.length?guaranteeList[0].code:'-')"> 复制 </span></a></div>
        
        <div class="property-subtitle2">承保公司：{{guaranteeList.length?guaranteeList[0].company:'-'}}</div>
      </div>
      <div class="title">保单服务</div>
      <div class="box-items">
        <div class="flex-box items" >
          <div  v-for="item in itemsTitle" :key="item.icon" @click="insuranceFun(item.name)">
            <img style="height:28px" :src="require('@/assets/icon/b'+item.icon+'.png')"/>
            <div>{{item.name}}</div>
          </div>
        </div>
      </div>
      <div class="title">保单简介</div>
      <div class="box-items">
        
        <div class="flex-box flex-row">
          <div>投保人</div>
          <div>{{guaranteeList.length?guaranteeList[0].holder:'-'}}</div>
        </div>
        <div class="flex-box  flex-row">
          <div>被保险人</div>
          <div>{{guaranteeList.length?guaranteeList[0].insured:'-'}}</div>
        </div>
        <div class="flex-box  flex-row">
          <div>签单日期</div>
          <div>{{guaranteeList.length?guaranteeList[0].date:'-'}}</div>
        </div>
        <!-- <div class="flex-box  flex-row">
          <div>基本保额</div>
          <div>{{guaranteeList.length?guaranteeList[0].coverage:'-'}}USD</div>
        </div> -->
        <div class="flex-box  flex-row">
          <div>缴费年限</div>
          <div>{{guaranteeList.length?guaranteeList[0].years:'-'}}年</div>
        </div>
        <div class="flex-box  flex-row">
          <div>保费</div>
          <div>{{guaranteeList.length?guaranteeList[0].premium:'-'|formatFloat}}USD/年</div>
        </div>
        <div class="flex-box  flex-row">
          <div>基本保额</div>
          <div>{{guaranteeList.length?guaranteeList[0].coverage:'-'|formatFloat}}USD</div>
        </div>
        <div class="flex-box  flex-row">
          <div>缴费方式</div>
          <div>按年缴纳</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header'
export default {
  components: {
    Header
  },
  data(){
    return {
      url:'',
      id:this.$route.query.id,
      guaranteeList:[],
      itemsTitle:[{
        name:"保单详情",
        icon:"1"
      },{
        name:"保障范围",
        icon:"2"
      },{
        name:"联系客服",
        icon:"3"
      }]
    }
  },
  created(){
    this.getInsurances()
  },
  methods:{
    getInsurances(){
      this.$store.dispatch('insurances/getInsurances').then(res => {
        this.guaranteeList = res.insurance.insurances.filter(item=>item.id==this.id)
      })
    },
     doCopy(id){
      let that =this
      this.$copyText(id).then(function () {
        that.$message({
          offset: 100,
          type: 'success',
          message: '复制成功',
          customClass:'message-tips'
        });
      }, function () {
           that.$message({
            offset: 100,
            type: 'error',
            message: '复制失败',
            customClass:'message-tips'
          });
      })
    },
    insuranceFun(name){
      if(name=="保单详情"){
        this.$router.push({path:'guaranteedetail',query:{url:this.guaranteeList[0].file_path}})
      }else if(name=="保障范围"){
        this.$router.push({path:'insuranceDetail',query:{name:this.guaranteeList[0].name}})
      }else if(name=="理赔须知"){
        // this.$router.push({path:'guaranteedetail'})
      }else if(name=="联系客服"){
        window.location.href="tel:4000362988"
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.my-box{
  .title{
    margin-bottom: 0px;
  }
}
.property{
  background: #ffffff;
  padding: 5% 4%;
  .property-title{
    font-size: 1.2rem;
    color: #333;
    margin-bottom:10px;
  }
  .property-subtitle{
    font-size: .9rem;
    color: #666;
    margin-bottom:30px;
  }
  .property-subtitle2{
    font-size: .9rem;
    color: #666;
    margin-bottom:8px;
  }
}
.more-service{
  line-height: 2.6rem;
  font-size: 1.1rem;
  color: #333;
}
.box-items-line{
  margin:2% 2.5%;
  padding:2% 3%;
  background: #ffffff;
  border-radius:5px;
  border:1px solid #eee;
  
}
.items{
  position: relative;
  justify-content:space-around;
  padding:1.5%;
  font-size: .9rem;
  text-align: center;
  img{
    height: 40px;
  }
}
.flex-row{
  justify-content: space-between;
  line-height: 2rem;
  color: #666;
  :first-child{
    color:#333;
    width: 90px;
    flex-shrink: 0;
  }
}
.finacing-footer{
  height: 3rem;
  border-top:1px solid #eee;
  .add-btn{
    border-radius:8px;
    width: 95%;
    margin: 2.5%;
    text-align: center;
    line-height: 2.5;
    color: #fff;
  }
}
</style>