var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "my-box" }, [
    _c(
      "div",
      { staticClass: "top-content" },
      [
        _c("Header", { attrs: { title: "保单详情", back: "my/guarantee" } }),
        _c("div", { staticClass: "property" }, [
          _c("div", { staticClass: "property-title" }, [
            _vm._v(
              _vm._s(_vm.guaranteeList.length ? _vm.guaranteeList[0].name : "-")
            ),
          ]),
          _c("div", { staticClass: "property-subtitle2" }, [
            _vm._v(
              "保单编号：" +
                _vm._s(
                  _vm.guaranteeList.length ? _vm.guaranteeList[0].code : "-"
                ) +
                " "
            ),
            _c("a", [
              _c(
                "span",
                {
                  staticClass: "theme-color",
                  on: {
                    click: function ($event) {
                      return _vm.doCopy(
                        _vm.guaranteeList.length
                          ? _vm.guaranteeList[0].code
                          : "-"
                      )
                    },
                  },
                },
                [_vm._v(" 复制 ")]
              ),
            ]),
          ]),
          _c("div", { staticClass: "property-subtitle2" }, [
            _vm._v(
              "承保公司：" +
                _vm._s(
                  _vm.guaranteeList.length ? _vm.guaranteeList[0].company : "-"
                )
            ),
          ]),
        ]),
        _c("div", { staticClass: "title" }, [_vm._v("保单服务")]),
        _c("div", { staticClass: "box-items" }, [
          _c(
            "div",
            { staticClass: "flex-box items" },
            _vm._l(_vm.itemsTitle, function (item) {
              return _c(
                "div",
                {
                  key: item.icon,
                  on: {
                    click: function ($event) {
                      return _vm.insuranceFun(item.name)
                    },
                  },
                },
                [
                  _c("img", {
                    staticStyle: { height: "28px" },
                    attrs: {
                      src: require("@/assets/icon/b" + item.icon + ".png"),
                    },
                  }),
                  _c("div", [_vm._v(_vm._s(item.name))]),
                ]
              )
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "title" }, [_vm._v("保单简介")]),
        _c("div", { staticClass: "box-items" }, [
          _c("div", { staticClass: "flex-box flex-row" }, [
            _c("div", [_vm._v("投保人")]),
            _c("div", [
              _vm._v(
                _vm._s(
                  _vm.guaranteeList.length ? _vm.guaranteeList[0].holder : "-"
                )
              ),
            ]),
          ]),
          _c("div", { staticClass: "flex-box flex-row" }, [
            _c("div", [_vm._v("被保险人")]),
            _c("div", [
              _vm._v(
                _vm._s(
                  _vm.guaranteeList.length ? _vm.guaranteeList[0].insured : "-"
                )
              ),
            ]),
          ]),
          _c("div", { staticClass: "flex-box flex-row" }, [
            _c("div", [_vm._v("签单日期")]),
            _c("div", [
              _vm._v(
                _vm._s(
                  _vm.guaranteeList.length ? _vm.guaranteeList[0].date : "-"
                )
              ),
            ]),
          ]),
          _c("div", { staticClass: "flex-box flex-row" }, [
            _c("div", [_vm._v("缴费年限")]),
            _c("div", [
              _vm._v(
                _vm._s(
                  _vm.guaranteeList.length ? _vm.guaranteeList[0].years : "-"
                ) + "年"
              ),
            ]),
          ]),
          _c("div", { staticClass: "flex-box flex-row" }, [
            _c("div", [_vm._v("保费")]),
            _c("div", [
              _vm._v(
                _vm._s(
                  _vm._f("formatFloat")(
                    _vm.guaranteeList.length
                      ? _vm.guaranteeList[0].premium
                      : "-"
                  )
                ) + "USD/年"
              ),
            ]),
          ]),
          _c("div", { staticClass: "flex-box flex-row" }, [
            _c("div", [_vm._v("基本保额")]),
            _c("div", [
              _vm._v(
                _vm._s(
                  _vm._f("formatFloat")(
                    _vm.guaranteeList.length
                      ? _vm.guaranteeList[0].coverage
                      : "-"
                  )
                ) + "USD"
              ),
            ]),
          ]),
          _vm._m(0),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-box flex-row" }, [
      _c("div", [_vm._v("缴费方式")]),
      _c("div", [_vm._v("按年缴纳")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }